// // 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
// @import "~perfect-scrollbar/css/perfect-scrollbar.css";

// // Theme Styles
// @import "./_metronic/_assets/sass/style.react.scss";

// // Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// // Header themes
// // Light
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// // Dark
// // @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// // Header Menu themes
// // Light
// //@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// // Dark
// // @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// // Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// // Aside themes
// // Dark
// //@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// // Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// // .example-highlight {
// //   padding: 0rem 1.5rem 1.75rem !important;
// // }

// .example-highlight > pre {
//   background: none transparent !important;
//   margin: 0 !important;
//   padding-top: 0.5rem;
//   code {
//     overflow-y: auto;
//     display: block;

//     span {
//       background: none transparent !important;
//     }
//   }
// }

img,
svg {
  vertical-align: middle;
}

// .json > pre {
//   background: none #fff !important;
// }

// .example-code > .json {
//   background: none #fff !important;
// }

// .symbol.symbol-45 > svg {
//   width: 100%;
//   max-width: 45px;
//   height: 45px;
// }

// .react-bootstrap-table {
//   overflow-x: auto;
// }

// .react-bootstrap-table {
//   th {
//     outline: none;

//     &.sortable {
//       cursor: pointer;
//       .svg-icon-sort {
//         opacity: 1;
//       }
//     }
//   }

//   .table.table-head-custom thead tr {
//     .sortable-active {
//       color: $primary !important;
//     }
//   }
// }

// .cursor-default {
//   cursor: default !important;
// }

// @mixin text-ellipsis {
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// }

// .text-truncate {
//   @include text-ellipsis;
// }

// .text-break {
//   overflow-wrap: break-word;
//   word-wrap: break-word;
//   word-break: break-word;
// }

// .input-feedback-text-placeholder {
//   height: 19px;
// }

// .color-inherit {
//   color: inherit;
// }

// .bg-table-warning {
//   background-color: #fff8e5;
// }

// .custom-control-label::after {
//   cursor: pointer;
// }

// .cursor-not-allowed {
//   cursor: not-allowed !important;
// }

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {

//   -webkit-appearance: none;
//   margin: 0;
// }
// input[type=number]{
//   -moz-appearance:textfield;
// }

// @import "modules/dashboard/Dashboard";
// @import "modules/dashboard/Map/Map";
// @import "modules/SPUnits/SPUnitDetails/Map/ShorePowerUnitMap";
// @import "modules/supplypits/supplyPitDetails/components/Map/SupplyPitOperationalMap";
// @import "modules/customers/CustomerDetails/CustomerDetails";

.react-pdf__Page__canvas {
  margin: 0 auto;
  padding-top: 10px;
  max-width: 100% !important;
}

.react-pdf__Page {
  background-color: #525659 !important;
}
